<template>
    <div class="v-modal">
        <loading-spinner v-if="isFormLoaded" class="LoadingSpinner--overlay"/>
        <div class="modal-content">
            <div class="modal-header question-form-header">
                <h3 class="block-title">
                    <span v-if="question">Редактировать вопрос</span>
                    <span v-else>Добавить вопрос</span>
                </h3>
                <div class="block-options close-question-form-wrap">
                    <button type="button" class="btn-block-option close-question-form close-modal"
                            @click="$modal.hide('question-change-modal')">×
                    </button>
                </div>
            </div>
            <div class="modal-body question-form-body">
                <ValidationObserver tag="form" ref="observerQuestion" class="form_question without_double_block"
                                    @submit.prevent="onSubmit">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 form-group">
                            <div class="form-material floating open"
                                 :class="{'is-invalid': !errors.selectMaterial.isValid || !this.errors.accessMaterial.isValid }">
                                <label for="id_material">
                                    Статья базы знаний *
                                </label>
                                <v-select2 class="form-material"
                                           id="id_material" v-model="form.material" :reduce="title => title.id"
                                           aria-selected="true" label="title" :clearable="false"
                                           :options="listMaterials">
                                    <span slot="no-options">Ничего не найдено</span>
                                </v-select2>
                                <div v-if="!errors.selectMaterial.isValid || !this.errors.accessMaterial.isValid " id="id_material-error"
                                     class="invalid-feedback animated fadeInDown" style="">{{ errors.selectMaterial.text }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 form-group">
                            <div class="form-group">
                                <label for="id_knowledge_block">Блок знаний</label>
                                <vue-bootstrap-typeahead
                                    id="id_knowledge_block"
                                    name="knowledge_block"
                                    v-model="form.knowledge_block"
                                    :data="knowledgeBlock"
                                    :serializer="s => s.name"
                                    ref="knowledgeBlockTypeahead"
                                />
                            </div>
                        </div>
                        <div class="form-group col-12" :class="{'is-invalid': errors.text}">
                            <div>
                                <label for="id_text">Введите формулировку вопроса *</label>
                                <textarea v-model="form.text" name="text" rows="3" maxlength="1024" id="id_text"
                                          class="form-control" cols="40"></textarea>
                                <div v-if="errors.text" id="id_text-error"
                                     class="invalid-feedback animated fadeInDown" style="">Обязательное поле.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 form-group">
                            <div class="form-material floating open" :class="{'is-invalid': errors.selectType}">
                                <label for="id_type_answer">
                                    Тип ответа *
                                </label>
                                <v-select2 ref="selectTypeAnswer" class="form-material" id="id_type_answer"
                                           v-model="form.type_answer" :reduce="label => label.value"
                                           label="label" :clearable="false" :searchable="false"
                                           :options="listTypeAnswer" @input="changeType">
                                    <span slot="no-options">Ничего не найдено</span>
                                </v-select2>
                                <div v-if="errors.selectType" id="id_type_answer-error"
                                     class="invalid-feedback animated fadeInDown" style="">Обязательное поле.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 form-group display_flex align-items-end">
                            <div class="question-logo">
                                <div class="logo__preview">
                                    <div
                                        class="logo__img-wrap"
                                        v-if="form.file"
                                        @click="showPhotoModal"
                                    >
                                        <img
                                            :src="preview"
                                            alt=""
                                            class="logo__img"
                                        >
                                        <i class="logo__img-icon"/>
                                    </div>
                                    <span v-else class="logo__letter"></span>
                                </div>
                                <div class="logo__wrap">
                                    <label v-if="!form.file" for="id_image" class="logo__load v-link">
                                        Загрузить изображение
                                    </label>
                                    <span @click="handleFileDelete" v-else class="logo__load v-link">
                                        Удалить изображение
                                    </span>
                                    <span class="logo__meta">
                                        {{!form.file ? 'jpeg, jpg, png, svg не более 2 мб' : 'Нажмите на изображение, чтобы просмотреть его'}}
                                    </span>
                                </div>
                                <div class="hidden">
                                    <input class="hidden" type="file" name="image" accept="image/*" id="id_image" ref="questionFile"
                                           @change="handleFileUpload">
                                    <input class="hidden" v-model="form.file">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive push">
                        <div class="form-group">
                            <div v-if="form.type_answer === 2" class="form-material floating open answer-choices">
                                <label class="">Правильный ответ *</label>
                                <textarea
                                    v-for="(item, index) in form.listAnswer" :key="'item_' + index"
                                    name="text" rows="3"
                                    maxlength="1024"
                                    class="form-control"
                                    cols="40"
                                    v-model="item.text"
                                    :id="'id_answer_set-' + index + '-text'"
                                    :name="'answer_set-' + index + '-text'"
                                >
                                </textarea>
                            </div>
                            <div v-else class="form-material floating open answer-choices">
                                <label class="">Укажите варианты ответа *</label>
                                <table class="table questions-table">
                                    <tbody>
                                    <tr class="form-set from-set-main-js"
                                        v-for="(item, index) in form.listAnswer" :key="'item_' + index">
                                        <td hidden="hidden">
                                            <input type="hidden" :name="'answer_set-' + index + '-id'"
                                                   :id="'id_answer_set-' + index + '-id'">
                                        </td>
                                        <td class="questions-table__text">
                                            <div class="open">
                                                <input type="text" class="form-control check_select"
                                                       maxlength="1024"
                                                       :class="{display_flex: item.text }"
                                                       v-model="item.text"
                                                       :id="'id_answer_set-' + index + '-text'"
                                                       :name="'answer_set-' + index + '-text'">
                                            </div>
                                        </td>
                                        <td class="questions-table__buttons person-rating">
                                            <label class="css-control css-control-success css-checkbox"
                                                   title="Правильный ответ" :class="{display_flex: item.text }">
                                                <input type="checkbox" class="css-control-input"
                                                       v-model="item.correct"
                                                       :name="'id_answer_set-' + index + '-correct'"
                                                       :id="'id_answer_set-' + index + '-correct'">
                                                <span class="css-control-indicator"></span>Правильный ответ
                                            </label>
                                            <button v-if="index > 1" type="button"
                                                    class="delete_result button-delete" title="Удалить"
                                                    @click="removeAnswer(index)"></button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div v-if="!this.errors.answer.isValid"
                                     class="showed-error-js invalid-feedback animated fadeInDown showed"
                                     style="display: block;">{{ this.errors.answer.error }}
                                </div>
                                <button type="button" class="add_one_question btn btn-alt-secondary btn-block"
                                        @click="addAnswer">
                                    + Добавить вариант ответа
                                </button>
                            </div>
                        </div>

                        <div class="question-permission">
                            <span v-show="!infoPermissionText">
                                Доступы не указаны
                            </span>
                            <span v-show="infoPermissionText">
                                <span class="material__access-txt">{{ infoPermissionText }}</span>
                            </span>
                            <a href="javascript:void(0)" class="question-permission__link"
                               @click="changePermission">
                                <svg width="15" height="15" viewBox="0 0 15 15"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.724 1.274a4.35 4.35 0 00-6.966 5.022L.006 13.05l-.004 1.947L3.778 15v-1.836h1.836v-1.837h1.837V9.491L8.7 8.24a4.351 4.351 0 005.023-6.966zm-1.383 3.243a1.315 1.315 0 11-1.86-1.86 1.315 1.315 0 011.86 1.86z"></path>
                                </svg>
                                Настроить доступ
                            </a>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-rounded btn-primary-dark">
                            <span v-if="question">Сохранить</span>
                            <span v-else>Добавить</span>
                        </button>
                        <button v-if="!question" type="button" class="btn btn-rounded btn-alt-secondary" @click="resetForm">
                            Сбросить
                        </button>
                        <button v-else type="button" class="btn btn-rounded btn-alt-secondary" @click="$modal.hide('question-change-modal')">
                             Отмена
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
        <vue-progress-bar/>
    </div>
</template>

<script>
    import session from '@/api/session';
    import LoadingSpinner from '@/components/LoadingSpinner';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import validation from "assets/js/validate";

    export default {
        name: 'QuestionChangeModal',
        components: {
            VueBootstrapTypeahead,
            LoadingSpinner
        },
        props: {
            permissionData: {
                type: Object,
                required: false
            },
            question: {
                type: Object,
                required: false
            },
            material_id: {
                required: false,
                default: null
            },
            material_title: {
                required: false,
                default: null
            },
            onSave: {
                type: Function
            },
        },
        data() {
            return {
                infoPermissionText: 'Доступен всей компании',
                form: {
                    right_answer: '',
                    permissionData: {structure: {}, users: [], access_all: true, note: {}},
                    material: '',
                    type_answer: 0,
                    text: '',
                    knowledge_block: '',
                    listAnswer: [
                        {
                            'text': '',
                            'correct': false
                        },
                        {
                            'text': '',
                            'correct': false
                        }
                    ],
                    file: ''
                },
                knowledgeBlock: [],
                listTypeAnswer: [
                    {
                        value: 0,
                        label: 'Один правильный ответ'
                    },
                    {
                        value: 1,
                        label: 'Несколько правильных ответов'
                    },
                    {
                        value: 2,
                        label: 'Открытый вопрос'
                    }
                ],
                errors: {
                    isValid: true,
                    selectMaterial: {
                        isValid: true,
                        error: ''
                    },
                    accessMaterial: {
                        isValid: true
                    },
                    selectType: false,
                    text: false,
                    answer: {
                        isValid: true,
                        error: ''
                    },
                },
                isValidSelect2: true,
                clickSubmit: false,
                listMaterials: [],
                preview: null,
                validation,
                isFormLoaded: false
            }
        },
        async created() {
            if (this.permissionData) {
                this.form.permissionData = this.permissionData;
                await this.setInfoText(this.permissionData);
            }
            session
                .get('/api/v1/material/all_list/')
                .then(response => {
                    this.listMaterials = response.data;
                    if (this.material_id) {
                        this.form.material =
                             Number(this.material_id)
                    }
                })
                .catch(() => {
                });
            session
                .get('/api/v1/knowledge-block/')
                .then(response => {
                    this.knowledgeBlock = response.data;
                })
                .catch(() => {
                });
            if (this.question) {
                session
                    .get(`/api/v1/question/${this.question.id}/`)
                    .then(response => {
                        this.form = response.data;
                        if (response.data.file) {
                            this.preview = response.data.file;
                        }
                        this.$refs.knowledgeBlockTypeahead.inputValue = this.form.knowledge_block;
                        this.form.type_answer = Number(this.form.type_answer);
                        this.infoPermissionText = response.data.permission_text;
                    })
                    .catch(() => {
                    });
            }
        },
        watch: {
            form: {
                handler: function (val, oldVal) {
                    if (this.clickSubmit) {
                        this.validateAnswer();
                        this.validateSelect2();
                    }
                },
                deep: true
            },
        },
        methods: {
            resetForm(){
                this.form = {
                    right_answer: '',
                    permissionData: this.permissionData ? this.permissionData : {structure: {}, users: [], access_all: true, note: {}},
                    material: '',
                    type_answer: '',
                    text: '',
                    knowledge_block: '',
                    listAnswer: [
                        {
                            'text': '',
                            'correct': false
                        },
                        {
                            'text': '',
                            'correct': false
                        }
                    ],
                    file: ''
                };
                this.$refs.knowledgeBlockTypeahead.inputValue = '';
                this.setInfoText(this.permissionData);
            },
            async setInfoText(data) {
                if (data) {
                    try {
                        const request = session.post('/api/v1/permission/info_text/', data);
                        let response = await request;
                        this.infoPermissionText = response.data['text'];
                    } catch (err) {
                        console.error(err)
                    }
                }
            },
            changeType(value) {
                if (value === 2) {
                    this.form.listAnswer = [
                        {
                            'text': '',
                            'correct': true
                        }
                    ]
                } else {
                    this.form.listAnswer = [
                        {
                            'text': '',
                            'correct': false
                        },
                        {
                            'text': '',
                            'correct': false
                        }
                    ]
                }
            },
            handleFileDelete() {
                this.form.file = null;
                this.preview = null;
            },
            async handleFileUpload(e) {
                let reader = new FileReader();
                let file = e.target.files[0];
                reader.addEventListener('load', function() {
                    let isValid = this.validation.file(file, ['jpg', 'jpeg', 'png', 'svg', 'JPG', 'JPEG', 'PNG', 'SVG'], 2000);
                    this.$refs.questionFile.value = null;
                    if (isValid) {
                        this.$Progress.start();
                        // this.form.file = file;
                        this.form.file = file;
                        this.preview = URL.createObjectURL(file);
                        this.isNewImage = true;
                        this.$Progress.finish();
                    }
                }.bind(this), false);
                if (file) {
                    reader.readAsDataURL(file);
                }
            },
            showPhotoModal() {
                const PhotoModal = () => import(`../../accounts/profile/PhotoModal`);
                this.$modal.show(PhotoModal, {
                    image: this.preview,
                    text: this.form.file.name
                }, {
                    name: 'photo-modal',
                    classes: 'photo-modal'
                })
            },
            async onSubmit() {
                let isValidQuestion = this.$refs.observerQuestion.validate();
                this.clickSubmit = true;
                await this.validateAnswer();
                await this.validateSelect2();

                if (this.errors.isValid && this.errors.answer.isValid && this.errors.accessMaterial.isValid) {
                    try {
                        let data = this.form;
                        let config = {};

                        this.isFormLoaded = true;

                        if (this.isNewImage) {
                            config['headers'] = {
                                'Content-Type': 'multipart/form-data'
                            };
                            // Добавляем данные из объекта в formData для отправки на сервер
                            let formData = new FormData();
                            for (let [key, value] of Object.entries(data)) {
                                //Если значение - массив, то переводим его значения в формат images[0]: file, images[1]: file...
                                if (key === 'permissionData') {
                                    formData.append('permissionData', JSON.stringify(value));
                                } else if (key === 'file') {
                                    formData.append('file', value);
                                } else if (key === 'listAnswer') {
                                    formData.append('listAnswer', JSON.stringify(value));
                                } else if (typeof (value) === 'object') {
                                    for (let item of value) {
                                        formData.append(`${key}[]`, JSON.stringify(item));
                                    }
                                } else {
                                    formData.append(key, value);
                                }
                            }
                            data = formData;
                        } else {
                            delete data.file;
                        }

                        if (this.question) {
                            let request = session.put(`/api/v1/question/${this.question.id}/`, data);
                            let response = await request;
                            this.$modal.hide('question-change-modal', {item: this.question, new_item: response.data});
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Вопрос изменен',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        } else {
                            let request = session.post('/api/v1/question/', data);
                            let response = await request;
                            this.$modal.hide('question-change-modal', {item: null, new_item: response.data});
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Вопрос добавлен',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        }
                        this.onSave();
                        this.isFormLoaded = false;
                    } catch (err) {
                        console.error(err);
                        this.isFormLoaded = false;
                        if (err.response) {
                            this.$refs.observerQuestion.setErrors(err.response.data);
                        }
                    }
                }
            },
            async validateAnswer() {
                let text_repeat = 0;
                let count_checkbox = 0;
                this.errors.answer.error = '';
                this.errors.answer.isValid = true;
                this.form.listAnswer.map((element) => {
                    if (element.text === '') {
                        this.errors.answer.error = 'Заполните все варианты ответов.';
                    }
                    if (element.correct) {
                        count_checkbox += 1;
                    }
                    this.form.listAnswer.map((item) => {
                        if (element.text && element.text === item.text && element.id !== item.id) {
                            text_repeat += 1
                        }
                    });
                });
                if (this.form.type_answer === this.listTypeAnswer[0].value && count_checkbox !== 1) {
                    this.errors.answer.error = 'Выберите правильный ответ.';
                }
                if (this.form.type_answer === this.listTypeAnswer[1].value && count_checkbox < 2) {
                    this.errors.answer.error = 'Выберите несколько правильных ответов.';
                }
                if (text_repeat > 0) {
                    this.errors.answer.error = 'Введите разные ответы.';
                }
                if (this.errors.answer.error) {
                    this.errors.answer.isValid = false;
                }
            },
            async validateSelect2() {
                this.errors.selectType = false;
                this.errors.selectMaterial.text = '';
                this.errors.selectMaterial.isValid = true;
                this.errors.text = false;
                this.errors.isValid = true;
                if (this.form.text.length === 0) {
                    this.errors.text = true;
                    this.errors.isValid = false;
                }
                if (this.form.material.length === 0) {
                    this.errors.selectMaterial.isValid = false;
                    this.errors.selectMaterial.text = 'Обязательное поле.';
                    this.errors.isValid = false;
                } else {
                    await this.accessMaterial();
                }
                if (this.form.type_answer.length === 0) {
                    this.errors.selectType = true;
                    this.errors.isValid = false;
                }
            },
            addAnswer() {
                this.form.listAnswer.push({
                    'text': '',
                    'correct': false
                })
            },
            removeAnswer(index) {
                this.form.listAnswer.splice(index, 1);
            },
            changePermission() {
                if (this.question) {
                    const ModalPermissionUpdate = () => import(`../../permission/ModalPermissionUpdate`);
                    this.$modal.show(ModalPermissionUpdate, {
                        title: 'Настройка доступа к вопросу',
                        text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному вопросу',
                        permissionType: 'question',
                        permissionObjectID: this.form.id,
                        showUsersTab: false
                    }, {
                        name: 'ModalPermissionUpdate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-close': this.updateTextPermission
                    })
                } else {
                    const ModalPermissionCreate = () => import(`../../permission/ModalPermissionCreate`);
                    this.$modal.show(ModalPermissionCreate, {
                        title: 'Настройка доступа к вопросу',
                        text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному вопросу',
                        permissionData: this.form.permissionData,
                        showUsersTab: false
                    }, {
                        name: 'ModalPermissionCreate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-close': this.updateTextPermission
                    })
                }
            },
            async updateTextPermission(data) {
                if (data.params) {
                    data = data.params.data;
                    this.form.permissionData = data;
                    this.infoPermissionText = data.info_text;
                    await this.accessMaterial()
                }
            },
            async accessMaterial() {
                if (this.form.material) {
                    try {
                        let request = session.post(
                            `/api/v1/testing_access/is_access_material/?material_id=${this.form.material}`,
                            this.form.permissionData
                        );
                        let response = await request;
                        this.errors.accessMaterial.isValid = true;
                        this.errors.selectMaterial.text = '';
                        if (response.data.is_access === false) {
                            this.errors.accessMaterial.isValid = false;
                            this.errors.selectMaterial.text = 'Доступы материала и вопроса не пересекаются.';
                        }
                    } catch (err) {
                        console.error(err);
                        if (err.response) {
                            this.$refs.observerQuestion.setErrors(err.response.data);
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    .question-logo {
        display: flex;
        align-items: center;
        .logo {
            &__preview {
                display: flex;
                margin: 0;
                cursor: pointer;
                height: 54px;
                width: 54px;
                max-height: 54px;
                max-width: 54px;
            }
            &__wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 15px;
            }
            &__load {
                cursor: pointer;
                color: #2173D3;
                font-weight: normal;
                margin: 0;
            }
            &__img {
                height: 54px;
                width: 54px;
                border-radius: 4px;
                filter: blur(3px) brightness(50%);
                &-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    &:hover {
                        i {
                            transform: scale(1.2);
                        }
                    }
                }
                &-icon {
                    transition: transform .25s ease;
                    @include icon($loup-icon, 25px);
                    position: absolute;
                }
            }
            &__letter {
                width: 100%;
                height: 100%;
                background: #5B6071;
                color: #A7AFCB;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.75rem;
                border-radius: 5px;
            }
            &__meta {
                color: #999;
                font-size: 0.875rem;
                line-height: 0.875rem;
            }
        }
    }
    .show {
        display: block;
    }

    .display_flex {
        display: flex !important;
    }

    .is_invalid {
        border-color: #ef5350 !important;
    }

    .is-invalid .v-select .vs__dropdown-toggle {
        border-color: #ef5350 !important;
    }
</style>
<style lang="scss" scoped>
@import "#sass/testing";
</style>
